const initialState = {
  id: 0,
  blocks: [],
  exam: {},
  end_exam_trigger: '',
  returned: false,
  student: {},
  timing: {},
}
export default initialState
