import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux'
import thunk from 'redux-thunk'
import modal from './reducers/modal'
import session from './reducers/session'

const rootReducer = combineReducers({
  modal,
  session,
})

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const createStore = () =>
  reduxCreateStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
export default createStore
